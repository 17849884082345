import { ChevronDownIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Box, Checkbox, Popover } from "@radix-ui/themes";
import { Command } from "cmdk";
import React, { forwardRef, useEffect, useId, useState } from "react";
import { useFieldArray, useFormContext } from "remix-validated-form";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";
import { Flex } from "../Flex/Flex";
import { Separator } from "../Separator/Separator";
import { Text } from "../Text/Text";
import { TextInput } from "../TextInput/TextInput";
import "./MultiSelect.css";

type MultiSelectProps = {
  label?: string;
  hint?: string;
  name?: string;
  placeholder?: string;
  shouldFilter?: boolean;
  defaultValue?: string[];
  options: { label: string; value: string }[];
  displayType?: "number" | "badge";
  selectedValues: { label: string; value: string }[];
  onChange?: (value: string[]) => void;
  onSearchChange?: (value: string) => void;
  onOptionToggle?: (value: string) => void;
  onClear?: () => void;
  onClearValue?: (value: string) => void;
  leftIcon?: React.ReactNode;
};

export const MultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      placeholder,
      shouldFilter = true,
      selectedValues,
      onSearchChange,
      onOptionToggle,
      onClear,
      leftIcon,
      displayType = "number",
      onClearValue,
    },
    ref,
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const toggleOption = (value: string) => {
      onOptionToggle?.(value);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const handleClear = () => {
      onClear?.();
    };

    const handleOnClearValue = (value: string) => {
      onClearValue?.(value);
    };

    return (
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Button
            ref={ref}
            className="Trigger"
            variant="surface"
            leftIcon={leftIcon}
            rightIcon={
              <Flex
                height="100%"
                align="center"
                justify="center"
                pl="3"
                style={{ borderLeft: "1px solid var(--gray-a6)" }}
              >
                <ChevronDownIcon />
              </Flex>
            }
            onClick={handleTogglePopover}
          >
            <Flex align="center" justify={"start"} gap="1">
              {displayType === "number" && (
                <>
                  <Text size="2" color="gray" className="ComboboxPlaceholder">
                    {placeholder}
                  </Text>
                  <Text size="2" color="green" weight="bold">
                    {selectedValues.length}
                  </Text>
                </>
              )}
              {displayType === "badge" && (
                <Flex align="center">
                  {selectedValues?.map((value) => {
                    return (
                      <Badge variant="solid" radius="full">
                        {value.label}
                        <Flex
                          style={{ cursor: "var(--cursor-button)" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOnClearValue(value.value);
                          }}
                        >
                          <Cross2Icon />
                        </Flex>
                      </Badge>
                    );
                  })}
                </Flex>
              )}
            </Flex>
          </Button>
        </Popover.Trigger>
        <Popover.Content style={{ width: 200, padding: 0, overflowX: "hidden" }} align="start">
          <Command loop shouldFilter={shouldFilter} className="Command">
            <Command.Input asChild>
              <Box p="1">
                <TextInput
                  placeholder="Search..."
                  className="CommandInput"
                  onChange={(e) => {
                    if (onSearchChange) {
                      onSearchChange(e.target.value);
                    }
                  }}
                />
              </Box>
            </Command.Input>
            <Separator />
            <Command.List asChild>
              <Command.Empty>
                <Box p="1">
                  <Text size="1" color="gray">
                    No results
                  </Text>
                </Box>
              </Command.Empty>
              <Command.Group style={{ maxHeight: 300, overflowY: "auto" }}>
                <Flex gap="1" direction="column" p="1">
                  {options.map((option) => {
                    const isSelected = selectedValues.findIndex((v) => v.value === option.value) !== -1;
                    return (
                      <Command.Item
                        className="CommandItem"
                        key={option.value}
                        onSelect={() => toggleOption(option.value)}
                        asChild
                      >
                        <Box px="2" py="1">
                          <Text as="label" size="2">
                            <Flex gap="2">
                              <Checkbox checked={isSelected} value={option.value} />
                              {option.label}
                            </Flex>
                          </Text>
                        </Box>
                      </Command.Item>
                    );
                  })}
                </Flex>
              </Command.Group>
              <Separator />
              <Command.Group>
                <Flex p="1" gap="1">
                  {selectedValues.length > 0 ? (
                    <>
                      <Command.Item
                        className="CommandItem"
                        onSelect={() => handleClear()}
                        style={{
                          pointerEvents: "auto",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Text size="2" color="gray">
                          Clear
                        </Text>
                      </Command.Item>
                      <Command.Separator data-accent-color="gray" className="CommandSeparator" />
                    </>
                  ) : null}
                  <Command.Item
                    className="CommandItem"
                    onSelect={() => setIsPopoverOpen(false)}
                    style={{
                      pointerEvents: "auto",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Text size="2" color="gray">
                      Close
                    </Text>
                  </Command.Item>
                </Flex>
              </Command.Group>
            </Command.List>
          </Command>
        </Popover.Content>
      </Popover.Root>
    );
  },
);

export const FormMultiSelect = ({ ...props }: MultiSelectProps) => {
  const randId = useId();
  const fieldName = props.name || randId;
  const formId = props.name ? undefined : `${randId}-form`;
  const [selectedValues, helpers, error] = useFieldArray(fieldName, {
    formId,
  });
  const { isSubmitting } = useFormContext(formId);

  useEffect(() => {
    if (props.defaultValue && !isSubmitting) {
      for (const value of props.defaultValue) {
        if (selectedValues.findIndex((v) => v.defaultValue === value) === -1) {
          helpers.push(value);
        }
      }
    }
  }, [props.defaultValue]);

  const toggleOption = (value: string) => {
    const index = selectedValues.findIndex((v) => v.defaultValue === value);
    if (index !== -1) {
      helpers.remove(index);
    } else {
      helpers.push(value);
    }
  };

  const handleClear = () => {
    for (let i = 0; i < selectedValues.length; i++) {
      helpers.pop();
    }
  };

  const component = (
    <MultiSelect
      {...props}
      selectedValues={selectedValues.map((v) => ({
        label: v.key,
        value: v.defaultValue,
      }))}
      onOptionToggle={toggleOption}
      onClear={handleClear}
    />
  );

  if (props.label) {
    return (
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {props.label}
        </Text>
        {component}
        {props.hint && !error ? (
          <Text as="div" size="1" color="gray" mt="1">
            {props.hint}
          </Text>
        ) : null}
        {error ? (
          <Text as="div" size="1" color="red" mt="1">
            {error}
          </Text>
        ) : null}
      </label>
    );
  }
  return component;
};
