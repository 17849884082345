import React, { forwardRef } from "react";
import {
  Container as RadixContainer,
  ContainerProps as RadixContainerProps,
} from "@radix-ui/themes";

type ContainerProps = RadixContainerProps;

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ maxWidth = "1920px", ...props }, forwardRef) => {
    return <RadixContainer maxWidth={maxWidth} {...props} ref={forwardRef} />;
  }
);
